var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c("div", { staticClass: "vx-row w-full" }, [
            _c("div", { staticClass: "vx-col sm:w-4/12 w-4/12" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questionnaire_corrector.avaliation"))),
              ]),
            ]),
            _c("div", { staticClass: "vx-col sm:w-5/12 w-5/12" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questionnaire_corrector.total_teacher"))),
              ]),
            ]),
            _c("div", { staticClass: "vx-col sm:w-3/12 w-3/12" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questionnaire_corrector.total_answers"))),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row w-full" }, [
            _c("div", { staticClass: "vx-col sm:w-6/12 w-4/12" }, [
              _vm._v(_vm._s(_vm.questionnaire.name)),
            ]),
            _c("div", { staticClass: "vx-col sm:w-3/12 w-5/12" }, [
              _vm._v(_vm._s(_vm.model.teachers_count)),
            ]),
            _c("div", { staticClass: "vx-col sm:w-3/12 w-3/12" }, [
              _vm._v(_vm._s(_vm.model.anwers_count)),
            ]),
          ]),
          _c("vx-card", { staticClass: "mb-4 mt-4" }, [
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("Grid", {
                  key: _vm.key,
                  attrs: {
                    service: _vm.service,
                    hide_searchbar: true,
                    order_column: "user_name",
                    route_name: "questionnaire_correctors",
                    route_grid_path: `${_vm.id}/dashboard/grid`,
                    column_formats: {
                      status: (val) =>
                        _vm.$t(`questionnaire_corrector.status.${val}`),
                    },
                    css_formats: {
                      status: (val) => {
                        switch (val) {
                          case null:
                            return "text-danger"
                          case "CP":
                            return "text-danger"
                          case "IC":
                            return "text-warning"
                          case "SR":
                            return "text-primary"
                          default:
                            return ""
                        }
                      },
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function (actions) {
                        return [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.auto",
                                value: {
                                  content: _vm.$t("action.open_correction"),
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                },
                                expression:
                                  "{\n                  content: $t('action.open_correction'),\n                  delay: {\n                    show: 500,\n                    hide: 500\n                  }\n                }",
                                modifiers: { auto: true },
                              },
                            ],
                            attrs: {
                              icon: "EyeIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                            },
                            on: {
                              click: () => _vm.openCorrection(actions.row),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-2 mt-8" }, [
            _c(
              "div",
              { staticClass: "w-full flex gap-2 justify-end" },
              [
                _c(
                  "vs-button",
                  { attrs: { type: "border" }, on: { click: _vm.goBack } },
                  [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }